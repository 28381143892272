
  import { Component, Watch } from 'vue-property-decorator'
  import BaseForm from '@/components/forms/view/BaseForm.vue'
  import { plainToInstance } from 'class-transformer'
  import { Appraisal } from '@/entities/purchase'
  import { Form } from '@/entities/public/Resource/metadata'
  import { fixPrice, isValidNumber } from '@/utils/general'
  import { AppraisalView } from '@/components/forms/view/AppraisalView'
  import StockPhotos from '@/components/toolkit/details/row/custom/StockPhotos.vue'
  import { Deal } from '@/entities/crm'
  import AutoResume from '@/components/forms/AutoResume.vue'
  import TechnicalSpecification from '@/components/forms/appraisal/TechnicalSpecification.vue'
  import GCostField from '@/components/core/input/GCostField.vue'
  import AppraisalResume from '@/components/toolkit/details/row/custom/AppraisalResume.vue'

@Component({
  components: { AppraisalResume, GCostField, TechnicalSpecification, AutoResume, StockPhotos, BaseForm },
  methods: { fixPrice },
  computed: {},
})
  export default class AppraisalForm extends AppraisalView {
  appraisal: Appraisal = plainToInstance(Appraisal, {})
  deal = null
  title = ''
  attributes = []
  idProcess = null
  subtitle = 'Gestiona tu tasación, apelando o agregando información relevante para definir el valor comercial del vehículo'
  showDetail = false
  errorEstimatedPrice = ''
  errorEstimatedPriceConsignment = ''
  formData = {
    offer1: null,
    offer2: null,
    appraisalInit: null,
    appraisalInitConsignment: null,
    comment: null,
    commentConsignment: null,
    companies: null,
    estimatedPrice: null,
    link: null,
  }

  metadata = {}
  metadataCollection = {}
  fields = {
    offer1: {
      properties: {
        label: '',
        link: '',
      },
    },
    offer2: {
      properties: {
        label: '',
        link: '',
      },
    },
    appraisalInit: {
      properties: {
        label: 'Oferta de compra',
      },
    },
    appraisalInitConsignment: {
      properties: {
        label: 'Oferta de consignación',
      },
    },
    comment: {
      properties: {
        label: 'Comentario oferta compra',
      },
    },
    commentConsignment: {
      properties: {
        label: 'Comentario',
      },
    },
    estimatedPrice: {
      properties: {
        label: 'Precio estimado de publicación',
      },
    },
    button1: '',
    button2: '',
  }

  async mounted () {
    await this.setMetadata()
    const { uid, id, model, metadataCollection, title } = this
    const appraisalId = id || uid
    if ((!isNaN(uid) && (model === 'Appraisal' || model === '')) || !isNaN(id)) {
      await this.getAppraisalInfo(appraisalId)
    }
    if (!this.isBreadCrumbPresent(title)) {
      this.setFormCrumbs(metadataCollection, title, Boolean(appraisalId))
    }
  }

  async getAppraisalInfo (id) {
    const appraisal = await this.fetchData({
      query: { name: 'fetch', model: 'Appraisal', params: { id } },
      force: true,
    })

    this.deal = appraisal.deal

    await this.findAlternativesAttributes(appraisal.deal.auto)
    const { metadataCollection } = this
    const { fields } = metadataCollection as Form

    const offer1 = appraisal?.externalOffers?.filter(offer => offer.company.id === fields.clicar_offer.init.value.company.id)
    this.formData.offer1 = offer1?.[0]?.amount
    const offer2 = appraisal?.externalOffers?.filter(offer => offer.company.id === fields.olx_offer.init.value.company.id)
    this.formData.offer2 = offer2?.[0]?.amount
    this.formData.estimatedPrice = appraisal?.expectedPublicationAmount
    this.formData.link = appraisal?.link
    if (appraisal?.status?.isAppraised) {
      const lastAppraised = appraisal.responses.find(ap => ap?.isAppraised || ap?.isAppraisedConsignment)

      if (lastAppraised) {
        const isAppraised = appraisal?.responses[0]?.type?.name.includes('appraised')
        this.formData.appraisalInit = isAppraised ? appraisal?.responses?.find(response => response.type.name === 'appraised')?.amount : null
        this.formData.comment = isAppraised ? appraisal?.responses?.find(response => response.type.name === 'appraised')?.comment : null
        this.formData.appraisalInitConsignment = isAppraised ? appraisal?.responses?.find(response => response.type.name === 'appraised_consignment')?.amount : null
        this.formData.commentConsignment = isAppraised ? appraisal?.responses?.find(response => response.type.name === 'appraised_consignment')?.comment : null
      }
    }

    this.appraisal = appraisal
    this.appraisal.deal.auto.dealAutoAttribute = await this.fetchData({
      query: { name: 'find', model: 'DealAutoAttribute' },
      filter: { id_process_record: { _eq: appraisal?.id }, process: { table_name: { _eq: 'appraisal' } } },
      force: true,
    })
    await this.setAppraisalInfo(appraisal)
    await this.setDetails()
  }

  async setAppraisalInfo (appraisal) {
    const process = await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'appraisal' } },
    })

    const idProcess = process[0].id

    this.idProcess = idProcess
    const cav = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: { _and: [{ id_process_record: { _eq: appraisal.id } }, { parameter: { process: { id: { _eq: idProcess } } } }, { parameter: { file_type: { name: { _eq: 'cav' } } } }] },
      force: true,
    })

    if (cav.length) {
      this.appraisal.cavValidation = {
        expirationDate: cav[0].file.expirationDate,
        validations: cav[0].validation,
        id: appraisal.id,
      }
    }
  }

  async setMetadata () {
    const { metadata } = this.getForm('Appraisal', 'appraiser_appraisal')
    const { fields, form } = metadata as Form
    this.metadataCollection = metadata
    this.title = form.title
    this.fields.offer1.properties.label = fields.clicar_offer.init.value.company.alias
    this.fields.offer2.properties.label = fields.olx_offer.init.value.company.alias

    this.formData.companies = {
      offer1: fields.clicar_offer.init.value.company,
      offer2: fields.olx_offer.init.value.company,
    }
    const person1 = await this.fetchData({
      query: { name: 'find', model: 'Person' },
      filter: { id: { _eq: fields.clicar_offer.init.value.company.id } },
    })
    const person2 = await this.fetchData({
      query: { name: 'find', model: 'Person' },
      filter: { id: { _eq: fields.olx_offer.init.value.company.id } },
    })

    const { webSite: webSite1 } = person1[0]
    const { webSite: webSite2 } = person2[0]
    this.fields.offer1.properties.link = webSite1
    this.fields.offer2.properties.link = webSite2

    this.fields.button1 = fields.button1.properties.label
    this.fields.button2 = fields.button2.properties.label
  }

  async send () {
    const errorMessage = this.errorEstimatedPrice?.length
    const isCommentOver = this.formData?.comment?.length > 250 || this.formData?.commentConsignment?.length > 250
    if (!this.$refs.form.validate() || errorMessage || isCommentOver) {
      return
    }
    if (!this.$refs.form.validate()) {
      return
    }

    const { appraisal: { id, responses, expectedPublicationAmount, appraiser }, formData } = this
    this.loadingForm = true
    if (id) {
      if (formData.appraisalInit || formData.appraisalInitConsignment) {
        await this.insertUpdateResponse(responses, formData, id)
        await this.pushData({
          model: 'Appraisal',
          fields: { id, id_process_status: this.statusAppraisal.appraised[0].id, link: formData.link },
        })
      }

      if (formData?.estimatedPrice !== expectedPublicationAmount) {
        await this.pushData({
          model: 'Appraisal',
          fields: { id, expected_publication_amount: formData.estimatedPrice, link: formData.link },
        })
      }
    }

    if (!appraiser) {
      await this.pushData({ model: 'Appraisal', fields: { id, id_appraiser: this.idEmployee, link: formData.link } })
    }

    await this.close()
  }

  async insertUpdateResponse (responses, formData, id) {
    const isAppraised = responses[0]?.type?.name.includes('appraised')
    const response = isAppraised ? responses.find(response => response.type.name === 'appraised') : null
    const responseConsignment = isAppraised ? responses.find(response => response.type.name === 'appraised_consignment') : null

    if (isValidNumber(formData.appraisalInitConsignment)) {
      if (responseConsignment?.isAppraisedConsignment) {
        await this.pushData({
          model: 'AppraisalResponse',
          fields: {
            id: responseConsignment.id,
            id_appraisal: id,
            amount: formData.appraisalInitConsignment,
            type: 'appraised_consignment',
            comment: formData.comment,
            id_employee: this.idEmployee,
          },
        })
      } else {
        await this.pushData({
          model: 'AppraisalResponse',
          fields: {
            id_appraisal: id,
            amount: formData.appraisalInitConsignment,
            type: 'appraised_consignment',
            comment: formData.comment,
            id_employee: this.idEmployee,
          },
        })
      }
    }
    if (isValidNumber(formData.appraisalInit) && this.displayPurchase) {
      if (response?.isAppraised) {
        await this.pushData({
          model: 'AppraisalResponse',
          fields: {
            id: response.id,
            id_appraisal: id,
            amount: formData.appraisalInit,
            type: 'appraised',
            comment: formData.comment,
            id_employee: this.idEmployee,
          },
        })
      } else {
        await this.pushData({
          model: 'AppraisalResponse',
          fields: {
            id_appraisal: id,
            amount: formData.appraisalInit,
            type: 'appraised',
            comment: formData.comment,
            id_employee: this.idEmployee,
          },
        })
      }
    }
  }

  async insertUpdateExternalOffer (externalOffers, formData, id) {
    const offer1 = externalOffers.filter(offer => offer.company.id === formData.companies.offer1.id)[0]
    if (offer1?.amount !== formData.offer1) {
      if (offer1?.id) {
        await this.pushData({
          model: 'AppraisalExternalOffer',
          fields: { id: offer1.id, amount: formData.offer1 },
        })
      } else {
        await this.pushData({
          model: 'AppraisalExternalOffer',
          fields: { id_appraisal: id, amount: formData.offer1, id_person: formData.companies.offer1.id },
        })
      }
    }
    const offer2 = externalOffers.filter(offer => offer.company.id === formData.companies.offer2.id)[0]
    if (offer2?.amount !== formData.offer2) {
      if (offer2?.id) {
        await this.pushData({ model: 'AppraisalExternalOffer', fields: { id: offer2.id, amount: formData.offer2 } })
      } else {
        await this.pushData({
          model: 'AppraisalExternalOffer',
          fields: { id_appraisal: id, amount: formData.offer2, id_person: formData.companies.offer2.id },
        })
      }
    }
  }

  get stock () {
    const { appraisal } = this

    return {
      auto: appraisal?.deal?.auto,
    }
  }

  get disabledChanges () {
    const { appraisal } = this

    return Boolean(appraisal?.closingReason)
  }

  get appraisalDisabled () {
    const { appraisal } = this

    const isAgreedAmount = Boolean(appraisal?.agreedAmount)

    if (isAgreedAmount) return isAgreedAmount

    const wasAppealed = Boolean(appraisal?.appeal?.isAppealed)

    if (wasAppealed) return !wasAppealed

    return (!appraisal?.status?.isPending && !appraisal?.status?.isNotOffer && !appraisal?.status?.isAppraised) && Boolean(appraisal?.id) && !appraisal?.status?.isAppealed
  }

  get isAppealed () {
    const { appraisal } = this

    return Boolean(appraisal?.status?.isAppealed)
  }

  get disabledResponse () {
    const { appraisal } = this

    return !appraisal?.status?.isPending && !appraisal?.status?.isNotOffer && !appraisal?.status?.isAppraised && !appraisal?.status?.isAppealed
  }

  get auto () {
    const { appraisal } = this

    return appraisal?.deal?.auto
  }

  get fuel () {
    const { attributes } = this

    return attributes.find(att => att.component?.slug === 'fuel').val
  }

  get transmission () {
    const { attributes } = this

    return attributes.find(att => att.component?.slug === 'transmission').val
  }

  get mileage () {
    const { appraisal } = this
    const att = appraisal?.deal?.autoAttributes?.find(att => att.slug === 'mileage')
    return fixPrice(att?.val, false)
  }

  get owners () {
    const { appraisal } = this

    const att = appraisal.deal.autoAttributes.find(att => att.slug === 'owners_number')

    return att?.val
  }

  get traction () {
    const { attributes } = this

    return attributes.find(att => att?.component?.slug === 'traction')?.val
  }

  async setDetails () {
    const { metadataCollection, appraisal } = this

    const person = await this.fetchData({
      query: { name: 'find', model: 'Person' },
      filter: { employee: { id: { _eq: appraisal.executive.id } } },
    })
    appraisal.deal.lead.executive.person = person[0]

    const customLead = await this.getLeadById(appraisal.deal.lead.id)

    appraisal.deal.lead.deals = customLead.deals?.map(deal => plainToInstance(Deal, deal))
    const sale = customLead.deals?.map(deal => plainToInstance(Deal, deal))?.find(deal => deal.isSale)
    if (sale) {
      const auto = await this.fetchData({
        query: { name: 'find', model: 'Auto' },
        filter: { id: { _eq: sale?.auto?.id } },
      })

      const stock = await this.fetchData({
        query: { name: 'findLite', model: 'Stock' },
        filter: { id: { _eq: sale?.stock?.id } },
      })

      appraisal.deal.lead.sale.auto = auto?.[0]
      appraisal.deal.lead.sale.stock = stock?.[0]
    }
    this.metadata = {
      data: appraisal,
      metadata: metadataCollection,
    }

    this.showDetail = Boolean(appraisal.id)
  }

  goToOfficialSite () {
    const { appraisal } = this
    if (!appraisal.deal?.auto?.officialSite) return null
    window.open(appraisal.deal?.auto?.officialSite, '_blank')
  }

  goToButton (field) {
    const { metadataCollection, stock } = this
    const { fields } = metadataCollection as Form

    const { init: { value: { url } } } = fields[field]

    this.openPortal(url, stock, false)
  }

  async findAlternativesAttributes (auto) {
    if (!auto?.version?.version?.id || !auto?.version?.year?.id) return

    let getGenerations = await this.getGeneration(auto?.version?.version?.id, auto?.version?.year?.id)

    let generation = getGenerations?.length === 1 ? getGenerations[0] : getGenerations.find(generation => generation.id === auto.generation?.id)

    if (generation?.attributes?.length) {
      this.attributes = generation?.attributes
      return
    }

    const attributes = await this.fetchData({
      query: {
        name: 'find',
        model: 'Attribute',
      },
      filter: {
        id_version_year: { _eq: auto.version.id },
      },
    })

    if (attributes?.length) {
      this.attributes = attributes
      return
    }

    if (!auto?.generation?.sku) {
      return
    }

    let cont = 1
    while (cont <= 3 && !generation?.attributes?.length) {
      getGenerations = await this.getGeneration(auto?.version?.version?.id, auto?.version?.year?.id - cont)

      generation = getGenerations?.length === 1 ? getGenerations[0] : getGenerations.find(generation => generation.sku === auto.generation?.sku)

      if (generation?.attributes?.length) {
        this.attributes = generation?.attributes || []
        break
      } else {
        cont++
      }
    }

    this.attributes = generation?.attributes || []
  }

  @Watch('formData', { immediate: true, deep: true })
  onFormDataChange (val) {
    if (val.estimatedPrice && val.appraisalInit && Number(val.estimatedPrice) < Number(val.appraisalInit)) {
      this.errorEstimatedPrice = 'La oferta de compra no puede ser mayor al precio estimado de publicación'
    } else {
      this.errorEstimatedPrice = ''
    }
    const validation = this.displayConsignment && val.appraisalInitConsignment
    if (validation && this.displayPurchase && val.appraisalInit && Number(val.appraisalInitConsignment) < Number(val.appraisalInit)) {
      this.errorEstimatedPriceConsignment = 'Tiene la oferta de consignación menor a la oferta de compra'
    } else if (validation && !this.displayPurchase && Number(val.estimatedPrice) < Number(val.appraisalInitConsignment)) {
      this.errorEstimatedPriceConsignment = 'La oferta de consignacion no puede ser mayor al precio estimado de publicación'
    } else {
      this.errorEstimatedPriceConsignment = ''
    }
  }

  async openAppraisalLink () {
    const { stock, appraisal } = this

    if (appraisal?.link) {
      this.openLink(appraisal.link)
      return
    }

    await this.buildLinkChileAutos(stock.auto)
  }

  get displayPurchase () {
    const { appraisal, maxMileage } = this

    return this.displayPurchaseFields(appraisal.kms, maxMileage)
  }
  }
